/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, Oem, EnvConfig} from './environments/env.types';

const processOem = (process.env.REACT_APP_OEM as Oem) || Oem.OHMCONNECT;
const processEnv = process.env.NODE_ENV as NodeEnv;

function getCurrentEnvConfig() {
  switch (processOem) {
    case Oem.ORIGIN:
      // @TODO: Setup Beta frontend for Origin (ON-5983: Current no domain setup for it)
      if (processEnv === NodeEnv.TEST) {
        return require('./environments/org/org.test.env').ORG_TEST_ENV;
      } else if (window.location.host.endsWith(':3000') || window.location.host.endsWith(':5000')) {
        // local server can run on ports 3000 (dev) or 5000 (serving production build)
        return require('./environments/org/org.development.env').ORG_DEVELOPMENT_ENV;
      } else if (window.location.host === 'beta.spike.originenergy.com.au') {
        return require('./environments/org/org.beta.env').ORG_BETA_ENV;
      } else {
        return require('./environments/org/org.production.env').ORG_PRODUCTION_ENV;
      }
    case Oem.TELUS:
      if (processEnv === NodeEnv.TEST) {
        return require('./environments/telus/telus.test.env').TELUS_TEST_ENV;
      } else if (window.location.host.endsWith(':3000') || window.location.host.endsWith(':5000')) {
        return require('./environments/telus/telus.development.env').TELUS_DEVELOPMENT_ENV;
      } else if (window.location.host === 'beta.telus.ohmconnect.com') {
        return require('./environments/telus/telus.beta.env').TELUS_BETA_ENV;
      } else if (window.location.host === 'alpha.telus.ohmconnect.com') {
        return require('./environments/telus/telus.alpha.env').TELUS_ALPHA_ENV;
      } else {
        return require('./environments/telus/telus.production.env').TELUS_PRODUCTION_ENV;
      }
    case Oem.NRG:
      if (processEnv === NodeEnv.TEST) {
        return require('./environments/nrg/nrg.test.env').NRG_TEST_ENV;
      } else if (
        ['ohm-lg-dev.ohmconnect.com', 'ohm-lg-dev-app.ohmconnect.com'].includes(
          window.location.host,
        )
      ) {
        return require('./environments/nrg/nrg.ngrok.env').NRG_NGROK_ENV;
      } else if (window.location.host.endsWith(':3000') || window.location.host.endsWith(':5000')) {
        return require('./environments/nrg/nrg.development.env').NRG_DEVELOPMENT_ENV;
      } else if (window.location.host === 'beta-frontend.ohmconnect.com') {
        return require('./environments/nrg/nrg.beta.env').NRG_BETA_ENV;
      } else if (
        window.location.host === 'staging-frontend.ohmconnect.com' ||
        window.location.host === 'staging-app.ohmconnect.com'
      ) {
        return require('./environments/nrg/nrg.staging.env').NRG_STAGING_ENV;
      } else if (window.location.host.endsWith('.amplifyapp.com')) {
        return require('./environments/nrg/nrg.reviewapp.env').NRG_REVIEWAPP_ENV;
      } else {
        return require('./environments/nrg/nrg.production.env').NRG_PRODUCTION_ENV;
      }
    case Oem.VIVINT:
      if (processEnv === NodeEnv.TEST) {
        return require('./environments/vivint/vivint.test.env').VIVINT_TEST_ENV;
      } else if (
        ['ohm-lg-dev.ohmconnect.com', 'ohm-lg-dev-app.ohmconnect.com'].includes(
          window.location.host,
        )
      ) {
        return require('./environments/vivint/vivint.ngrok.env').VIVINT_NGROK_ENV;
      } else if (window.location.host.endsWith(':3000') || window.location.host.endsWith(':5000')) {
        return require('./environments/vivint/vivint.development.env').VIVINT_DEVELOPMENT_ENV;
      } else if (window.location.host === 'beta-frontend.ohmconnect.com') {
        return require('./environments/vivint/vivint.beta.env').VIVINT_BETA_ENV;
      } else if (
        window.location.host === 'staging-frontend.ohmconnect.com' ||
        window.location.host === 'staging-app.ohmconnect.com'
      ) {
        return require('./environments/vivint/vivint.staging.env').VIVINT_STAGING_ENV;
      } else if (window.location.host.endsWith('.amplifyapp.com')) {
        return require('./environments/vivint/vivint.reviewapp.env').VIVINT_REVIEWAPP_ENV;
      } else {
        return require('./environments/vivint/vivint.production.env').VIVINT_PRODUCTION_ENV;
      }
    case Oem.VERIZON:
      if (processEnv === NodeEnv.TEST) {
        return require('./environments/verizon/verizon.test.env').VERIZON_TEST_ENV;
      } else if (
        ['ohm-lg-dev.ohmconnect.com', 'ohm-lg-dev-app.ohmconnect.com'].includes(
          window.location.host,
        )
      ) {
        return require('./environments/verizon/verizon.ngrok.env').VERIZON_NGROK_ENV;
      } else if (window.location.host.endsWith(':3000') || window.location.host.endsWith(':5000')) {
        return require('./environments/verizon/verizon.development.env').VERIZON_DEVELOPMENT_ENV;
      } else if (window.location.host === 'beta-frontend.ohmconnect.com') {
        return require('./environments/verizon/verizon.beta.env').VERIZON_BETA_ENV;
      } else if (
        window.location.host === 'staging-frontend.ohmconnect.com' ||
        window.location.host === 'staging-app.ohmconnect.com'
      ) {
        return require('./environments/verizon/verizon.staging.env').VERIZON_STAGING_ENV;
      } else if (window.location.host.endsWith('.amplifyapp.com')) {
        return require('./environments/verizon/verizon.reviewapp.env').VERIZON_REVIEWAPP_ENV;
      } else {
        return require('./environments/verizon/verizon.production.env').VERIZON_PRODUCTION_ENV;
      }
    default:
      if (processEnv === NodeEnv.TEST) {
        return require('./environments/ohm/ohm.test.env').OHM_TEST_ENV;
      } else if (
        ['ohm-lg-dev.ohmconnect.com', 'ohm-lg-dev-app.ohmconnect.com'].includes(
          window.location.host,
        )
      ) {
        return require('./environments/ohm/ohm.ngrok.env').OHM_NGROK_ENV;
      } else if (window.location.host.endsWith(':3000') || window.location.host.endsWith(':5000')) {
        return require('./environments/ohm/ohm.development.env').OHM_DEVELOPMENT_ENV;
      } else if (window.location.host === 'beta-frontend.ohmconnect.com') {
        return require('./environments/ohm/ohm.beta.env').OHM_BETA_ENV;
      } else if (
        window.location.host === 'staging-frontend.ohmconnect.com' ||
        window.location.host === 'staging-app.ohmconnect.com'
      ) {
        return require('./environments/ohm/ohm.staging.env').OHM_STAGING_ENV;
      } else if (window.location.host.endsWith('.amplifyapp.com')) {
        return require('./environments/ohm/ohm.reviewapp.env').OHM_REVIEWAPP_ENV;
      } else {
        return require('./environments/ohm/ohm.production.env').OHM_PRODUCTION_ENV;
      }
  }
}
// @todo Let's consider using amplify env vars for this, since that'll be more flexible.
/** The current app environment configuration */
export const ENV: EnvConfig = {
  ...getCurrentEnvConfig(),
  // supercede whatever `oem` and `nodeEnv` is defined in the config with the actual current node env
  oem: processOem,
  nodeEnv: processEnv,
};

/* Safari for Mac implements native sharing, but we only want to present
    the share sheet to mobile users. So we check whether we're on a Mac.
    This is a little hacky, but should have no impact on other platforms
    or browsers (if Chrome or Firefox for Mac were to implement
    `navigator.share`, we'd need to confirm that they continue to return
    'MacIntel' here, which they currently do).
*/
const BLOCKED_SHARE_PLATFORMS = ['MacIntel', 'Win32', 'Win64'];
export const CAN_NAVIGATOR_SHARE =
  navigator['share'] && !BLOCKED_SHARE_PLATFORMS.includes(navigator.platform);

// Stop polling after 10 minutes
export const INTERVAL_POLLING_EXPIRY = 10 * 60 * 1000;

/** A summary of the current environment */
const envSummary = `${ENV.name} (${ENV.oem} / ${ENV.nodeEnv} build)`;
/** Make the env summary accessible on the window */
window.env = envSummary;
