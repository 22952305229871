/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {keyMirror} from 'utils';
import {ThemeConfig} from './theme.types';

export * from './theme.types';

/**
 * Array of all available themes
 */
export const THEMES: ThemeConfig[] = [
  require('./ohmLight').themeConfig,
  require('./originLight').themeConfig,
  require('./telusLight').themeConfig,
  require('./nrgLight').themeConfig,
  require('./vivintLight').themeConfig,
  require('./verizonLight').themeConfig,
  /** Only for testing purposes */
  // require('./ohmDark').themeConfig,
];

/**
 * Array of all available theme ids
 */
export const THEME_IDS: string[] = THEMES.map(t => t.themeId);
/**
 * Enum of Theme Ids
 */
export const THEME_ID: Record<string, string> = keyMirror(THEME_IDS) as Record<string, string>;
