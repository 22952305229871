/* Copyright (C) 2022 OhmConnect, Inc. - All Rights Reserved */
import {UserSubscriptions} from './subscriptions.types';

// Ohm has all subscriptions available
export const VERIZON_SUBSCRIPTIONS: UserSubscriptions = {
  account: {
    account_inactivity: {label: 'Inactivity warnings', email: 'account_inactivity'},
    account_store_purchases: {label: 'Store Purchases', email: 'account_store_purchases'},
  },
  device: {
    device_activation: {label: 'Device Activation Help', email: 'device_activation'},
    device_connection: {
      label: 'Device Connection Issues',
      email: 'device_connection',
      sms: 'device_connection_sms',
    },
    device_ohmhour_issues: {
      label: 'Energy Event Device Issues',
      email: 'device_ohmhour_issues',
    },
  },
  onboarding: {
    onboarding_account_activity: {
      label: 'Account Activity',
      email: 'onboarding_account_activity',
    },
    onboarding_help: {
      label: 'Onboarding Help',
      email: 'onboarding_help',
      sms: 'onboarding_help_sms',
    },
    onboarding_referral: {label: 'Referral Onboarding Help', email: 'onboarding_referral'},
    onboarding_utility_activation: {
      label: 'Utility Account Activation Help',
      email: 'onboarding_utility_activation',
      sms: 'onboarding_utility_activation_sms',
    },
    onboarding_utility_changes: {
      label: 'Utility Account Changes',
      email: 'onboarding_utility_changes',
    },
  },
  promos: {
    promos_offers: {label: 'Promotions & Offers', email: 'promos_offers', sms: 'promos_offers_sms'},
    promos_surveys: {label: 'User Surveys', email: 'promos_surveys'},
    promos_weekly_summary: {
      label: 'Weekly Energy Snapshot',
      email: 'promos_weekly_summary',
    },
  },
  social: {
    social_referral_activation: {
      label: 'Referral Activation Activity',
      email: 'social_referral_activation',
    },
    social_referral_activity: {
      label: 'Referral Activity',
      email: 'social_referral_activity',
    },
    social_team_help: {label: 'Nearby Team Help', email: 'social_team_help'},
    social_team_updates: {label: 'Team Updates', email: 'social_team_updates'},
  },
};
