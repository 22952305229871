/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {NodeEnv, EnvConfig} from '../env.types';

export const VERIZON_STAGING_ENV: EnvConfig = {
  ...(require('./verizon.beta.env').VERIZON_BETA_ENV as EnvConfig),
  //
  nodeEnv: NodeEnv.PRODUCTION,
  name: 'Verizon Staging',
  backendBaseUrl: 'https://login.ohmconnect.com',
};
