/*  Copyright (C) 2021 OhmConnect, Inc. - All Rights Reserved  */
import {UserSubscriptions} from 'config/subscriptions';
import {Country} from 'config/regions';

export enum LoginMethod {
  SSO = 'sso',
  PASSWORD = 'password',
}

/** Enum of SnowPlow collector environments */
export enum SnowPlowUrl {
  OHM = 'collector.ohmconnect.com',
  ORG = 'collector-003.origin.ohmconnect.com',
}

/** Environment configuration */
export type EnvConfig = {
  /** OEM */
  oem: Oem;
  /** Name of the OEM, for example "OhmConnect" vs "Origin Spike" */
  oemName: string;

  /** Node environment, member of `NodeEnv` enum. (development, production, or test) */
  nodeEnv: NodeEnv;
  /** Descriptive name of the environment config */
  name: string;
  /** Country the environment is based in */
  country: Country;

  /** Backend base url */
  backendBaseUrl: string;

  /** Login Auth Urls */
  loginUrl: string;
  ssoLoginUrl: string;
  defaultLoginMethod: LoginMethod.SSO | LoginMethod.PASSWORD;

  /** Google Recaptcha site key */
  googleRecaptchaSiteKey: string;
  /** Hcaptcha site key */
  hcaptchaSiteKey: string;

  /** Base document title */
  baseDocumentTitle: string;

  /** Name of day ahead events, for example "OhmHour" vs "SpikeHour" */
  reductionEvent: string;
  /** Name of realtime events, for example "AutoOhm" vs "AutoSpike" */
  realtimeEvent: string;

  /** Link to go "back" to an external utility account dashboard from our dashboard */
  dashboardAccountLink: string;

  /** Social */
  /** Facebook app id */
  facebookAppId: string;
  /** Gmail client id */
  gmailClientId: string;
  /** Facebook page url */
  facebookPageUrl: string;
  /** Instagram page url */
  instagramPageUrl: string;
  /** Twitter page url */
  twitterPageUrl: string;

  /** Third-party app keys */
  /** Full story organization id */
  fullstoryOrganizationId: string;
  /** OCE enrollment url */
  oceEnrollmentUrl: string;
  /** FriendBuy Merchant ID */
  friendBuyMerchantId: string;

  /** Common links to external pages */
  /** Ask short url */
  askShortUrl: string;
  /** Help short url */
  helpShortUrl: string;
  /** Help form url */
  helpFormUrl: string;
  /** help email */
  helpEmail: string;
  /** Privacy policy url */
  privacyPolicyUrl: string;
  /** Terms of service url */
  termsUrl: string;
  /** Prize program rules url */
  prizeRulesUrl: string;
  /** Lists the shopify_products.variant_id for products that contain multiple devices,
  even when quantity = 1, for example:  2-Pack of OhmPlugs */
  shopifyBundlesVariantIds: number[];

  /** Cookie targeting allowed id */
  cookieTargetingAllowedId: string;
  /** Cookie domain */
  cookieDomain: string | undefined;

  /** Google RHR enroll url */
  googleRhrEnrollUrl: string;

  subscriptionList: Partial<UserSubscriptions>;

  /* Hosted SnowPlow collector */
  snowplowCollectorUrl: SnowPlowUrl.OHM | SnowPlowUrl.ORG;

  /* Reward marketplace Watt to dollar conversion rate */
  wattConversionRate: number;

  /** The base url to the EMS app */
  emsAppUrl: string;
};

/** Enum of OEMs */
export enum Oem {
  /** Origin Spike OEM */
  ORIGIN = 'org',
  /** OhmConnect OEM */
  OHMCONNECT = 'ohm',
  /** Telus OEM */
  TELUS = 'telus',
  /** NRG OEM */
  NRG = 'nrg',
  /** Vivint OEM */
  VIVINT = 'vivint',
  /** Verizon OEM */
  VERIZON = 'verizon',
}

/** Enum of node environments */
export enum NodeEnv {
  /** Node development environment */
  DEVELOPMENT = 'development',
  /** Node production environment */
  PRODUCTION = 'production',
  /** Node test environment */
  TEST = 'test',
}
