/* eslint-disable max-len */
/**
 * This is the cannonical list of the features available
 *
 * DO NOT EDIT THIS FILE!
 * It is auto generated by running `python -m scripts.sync_features write`
 */

/** Features */
export const OhmFeatures = {
  /** For Admin features! */
  Admin: {
    /** For administering the experience manager */
    ExperienceManager: {
      /** allows the user to see the full system list of features, not just the ones granted to them */
      ListAllFeatures: 'Admin.ExperienceManager.ListAllFeatures',
      /** ability to read the feature descriptions, i.e.:
       * - the description (what is in these docstrings)
       * - the per-user override state */
      ViewFeatureDetails: 'Admin.ExperienceManager.ViewFeatureDetails',
      /** allow overriding features */
      OverrideManagement: 'Admin.ExperienceManager.OverrideManagement',
      /** the floating, inline UI tool for managing feature overrides in real-time */
      FloatingUITool: 'Admin.ExperienceManager.FloatingUITool',
    },
    /** A test feature -- only visible to admin users -- that just tells people whether they have it or not via /internal/features/test! */
    TestFeature: 'Admin.TestFeature',
  },
  /** Utility-specific features (or specific utilities?) */
  Utility: {
    /** Access to the OhmConnect Internal Utility */
    OhmInternal: 'Utility.OhmInternal',
    /** On the utility selection screen, only inlcude utilities that the user is actually eligible for */
    OnlyIncludeEligibleUtilitiesOnSelect: 'Utility.OnlyIncludeEligibleUtilitiesOnSelect',
    /** On the utility selection screen, if user selects other utility redirect them to a ineligible screen */
    BlockOtherUtilitySignup: 'Utility.BlockOtherUtilitySignup',
  },
  /** features relating to the display of the main product landing page */
  Dashboard: {
    /** Show a notification when an achievement milestone is reached */
    AchievementNotification: 'Dashboard.AchievementNotification',
    /** Show a summary of the impact page on dashboard that links to impact page */
    ImpactSummary: 'Dashboard.ImpactSummary',
    /** redirect / to /invite */
    RedirectDashboardToReferrals: 'Dashboard.RedirectDashboardToReferrals',
    /** block redirect from /dashboard to / */
    BlockDashboardPathRedirect: 'Dashboard.BlockDashboardPathRedirect',
    /** display a badge indicating tier status on the dashboard */
    TierStatusBadge: 'Dashboard.TierStatusBadge',
    /** display a badge indicating streak length on the dashboard */
    StreakBadge: 'Dashboard.StreakBadge',
    /** Show wheel spin count on dashboard */
    WheelSpinCount: 'Dashboard.WheelSpinCount',
    /** Show prize entry count on dashboard */
    PrizeEntryCount: 'Dashboard.PrizeEntryCount',
    /** display reward currency balance on the dashboard */
    RewardCurrencyBalance: 'Dashboard.RewardCurrencyBalance',
    /** show status of a monthly payout period */
    MonthlyPayoutStatus: 'Dashboard.MonthlyPayoutStatus',
    /** Back button on dashboard/home page that links to external account page */
    AccountBackButton: 'Dashboard.AccountBackButton',
    /** Help icon on the dashboard that opens the help page */
    HelpButton: 'Dashboard.HelpButton',
    /** show a link to apply Watts as a bill credit */
    BillCreditLink: 'Dashboard.BillCreditLink',
    /** show background and container styles for lists on the dashboard */
    ShowListContainerStyle: 'Dashboard.ShowListContainerStyle',
    /** show the quit carbon section on the dashboard */
    ShowQuitCarbonSection: 'Dashboard.ShowQuitCarbonSection',
    /** Show the smart meter upgrade widget for basic meter users (made for Origin) */
    SmartMeterUpgradeWidget: 'Dashboard.SmartMeterUpgradeWidget',
    /** devices UI on the dashboard */
    DevicesUI: {
      /** show devices section header on the dashboard */
      ShowDevicesSectionHeader: 'Dashboard.DevicesUI.ShowDevicesSectionHeader',
      /** show verizon devices section header on the dashboard */
      ShowVerizonDevicesSectionHeader: 'Dashboard.DevicesUI.ShowVerizonDevicesSectionHeader',
      /** enable verizon theme */
      EnableVerizonTheme: 'Dashboard.DevicesUI.EnableVerizonTheme',
    },
    /** events UI on the dashboard */
    EventsUI: {
      /** UI for list with no events */
      EmptyEventList: {
        /** copy variation for OhmPerks program */
        OhmPerksCopy: 'Dashboard.EventsUI.EmptyEventList.OhmPerksCopy',
      },
      /** show events section header on the dashboard */
      ShowEventsSectionHeader: 'Dashboard.EventsUI.ShowEventsSectionHeader',
      /** show events on the dashboard */
      ShowEventsSection: 'Dashboard.EventsUI.ShowEventsSection',
      /** show past events on the dashboard */
      ShowPastEvents: 'Dashboard.EventsUI.ShowPastEvents',
      /** show planned events on the dashboard */
      ShowPlannedEvents: 'Dashboard.EventsUI.ShowPlannedEvents',
      /** show in progress events on the dashboard */
      ShowInProgressEvents: 'Dashboard.EventsUI.ShowInProgressEvents',
      /** show 'no events' message when there are no planned, in progress, or past events */
      ShowNoEventsMessage: 'Dashboard.EventsUI.ShowNoEventsMessage',
    },
    /** features related to UX prompting member to set a streak goal */
    StreakGoalUI: {
      /** whether to display the feature in California (via experiment) */
      ShowStreakGoalUI: 'Dashboard.StreakGoalUI.ShowStreakGoalUI',
    },
    /** whether to display the rate plan action banner */
    ShowRatePlanActionBanner: 'Dashboard.ShowRatePlanActionBanner',
    /** whether to display the NBA placement */
    ShowNextBestActionPlacement: 'Dashboard.ShowNextBestActionPlacement',
    /** whether to display the PCE single-item placement */
    ShowPrioritizedContentSingleItemPlacement:
      'Dashboard.ShowPrioritizedContentSingleItemPlacement',
    /** whether to display the footer on the dashboard */
    ShowFooter: 'Dashboard.ShowFooter',
  },
  /** features related to giveaways & coupons */
  GiveawayOffer: {
    /** message for members who are ineligible for a giveaway for reasons not otherwise covered */
    GenericIneligibleCopy: 'GiveawayOffer.GenericIneligibleCopy',
  },
  /** features related to member impact page */
  ImpactPage: {
    /** allows a user to view the Impact Page */
    View: 'ImpactPage.View',
    /** show the steak section on the Impact Page */
    ShowStreakSection: 'ImpactPage.ShowStreakSection',
    /** show the streak section on Impact Page for perks */
    ShowStreakSectionInLifetimeImpact: 'ImpactPage.ShowStreakSectionInLifetimeImpact',
    /** show status section on the Impact Page */
    ShowStatusSection: 'ImpactPage.ShowStatusSection',
    /** show events count */
    ShowEvents: 'ImpactPage.ShowEvents',
    /** show overall events count */
    ShowOverallEvents: 'ImpactPage.ShowOverallEvents',
    /** show savings in kWh section */
    ShowSavingsInKWh: 'ImpactPage.ShowSavingsInKWh',
    /** show earings in watts section */
    ShowEarningsinWatts: 'ImpactPage.ShowEarningsinWatts',
    /** show linked devices section on the Impact Page */
    ShowLinkedDevices: 'ImpactPage.ShowLinkedDevices',
    /** show the achievements component on the Impact Page */
    ShowAchievements: 'ImpactPage.ShowAchievements',
  },
  /** Features relating to the share-to-social features */
  ShareToSocial: {
    /** Show the share to social button across the app */
    ShareButton: 'ShareToSocial.ShareButton',
  },
  /** features related to tier status page */
  StatusPage: {
    /** allows a user to view the Status Page */
    View: 'StatusPage.View',
  },
  /** W9 Related feature not specific to RewardsMarketplace or Payments groups */
  W9: {
    /** Do not process W9s in validators */
    Skip: 'W9.Skip',
  },
  /** Overall rewards marketplace features
   * Note that redemption and visibility are gated behind segmentation directly via inclusion of the Segment in the accompanying Reward sheet. */
  RewardsMarketplace: {
    /** features related to the rewards page header */
    RewardsPageHeaderUI: {
      /** show a message when the user is in utility limbo */
      ShowLimboUtilityMessage: 'RewardsMarketplace.RewardsPageHeaderUI.ShowLimboUtilityMessage',
      /** show a back button in rewards page header */
      ShowBackButton: 'RewardsMarketplace.RewardsPageHeaderUI.ShowBackButton',
    },
    /** display a badge indicating tier status on the marketplace page */
    TierStatusBadge: 'RewardsMarketplace.TierStatusBadge',
    /** display an interactive badge indicating tier status on the marketplace page, linking to the status page */
    TierStatusInteractiveBadge: 'RewardsMarketplace.TierStatusInteractiveBadge',
    /** Anything relating to *The Wheel* */
    Wheel: {
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.Wheel.TierStatusDiscount',
      /** to include default wheel prizes */
      Default: 'RewardsMarketplace.Wheel.Default',
      /** use probability configuration that has less cash expense */
      LowCashProbability: 'RewardsMarketplace.Wheel.LowCashProbability',
      /** additional special slot on the wheel for daily cash prize */
      DailyCashPrize: 'RewardsMarketplace.Wheel.DailyCashPrize',
      /** additional special slot on the wheel for daily device prize */
      DailyDevicePrize: 'RewardsMarketplace.Wheel.DailyDevicePrize',
      /** the user is always ineligible for the daily cash prize */
      DailyCashAlwaysIneligible: 'RewardsMarketplace.Wheel.DailyCashAlwaysIneligible',
      /** the user is always ineligible for the daily device prize */
      DailyDeviceAlwaysIneligible: 'RewardsMarketplace.Wheel.DailyDeviceAlwaysIneligible',
      /** Enable the requirement of spending Watts on the wheel */
      EnableWattRedemption: 'RewardsMarketplace.Wheel.EnableWattRedemption',
      /** Enable the ticker under the wheel */
      EnableTicker: 'RewardsMarketplace.Wheel.EnableTicker',
      /** (made for origin) don't include wheel spins in the marketplace history csv */
      HideWheelSpinsInRewardMarketplaceHistory:
        'RewardsMarketplace.Wheel.HideWheelSpinsInRewardMarketplaceHistory',
    },
    /** Features controlling special versions of the reward marketplace email generator */
    EmailGenerator: {
      /** Origin reward marketplace email generator */
      Origin: 'RewardsMarketplace.EmailGenerator.Origin',
    },
    /** Entries into prize drawings */
    PrizeEntries: {
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.PrizeEntries.TierStatusDiscount',
      /** Enable spending Watts on a prize entry */
      EnableWattRedemption: 'RewardsMarketplace.PrizeEntries.EnableWattRedemption',
      /** Show text explaining how to earn prize entries (made for Origin) */
      EventReductionExplanationUI: 'RewardsMarketplace.PrizeEntries.EventReductionExplanationUI',
    },
    /** Covers both On-demand gift cards and coupons
     * NOTE: grouping coupons in here is a specific choice,
     * because it is how PM or others will approach it
     * The features here generally map to the specific inventory sources */
    GiftCards: {
      /** for gift cards that we generate On-Demand */
      OnDemand: {
        /**  */
        Amazon: {
          /** can receive a discount based on tier level */
          TierStatusDiscount: 'RewardsMarketplace.GiftCards.OnDemand.Amazon.TierStatusDiscount',
        },
      },
      /** for gift cards which are managed as coupon codes (purchased in advance and inventoried) */
      Coupons: {
        /** can receive a discount based on tier level */
        TierStatusDiscount: 'RewardsMarketplace.GiftCards.Coupons.TierStatusDiscount',
      },
    },
    /** Devices ordered from Shopify */
    Devices: {
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.Devices.TierStatusDiscount',
    },
    /** Ohm merchandise */
    Swag: {
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.Swag.TierStatusDiscount',
    },
    /** Donations to other organizations */
    Donation: {
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.Donation.TierStatusDiscount',
    },
    /** controls Paypal cashout in rewards marketplace */
    Cashout: {
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.Cashout.TierStatusDiscount',
    },
    /** Controls Watts to Bill Credit in rewards marketplace */
    BillCredit: {
      /** Moves the bill credit section to a row/carousel style */
      RowStyle: 'RewardsMarketplace.BillCredit.RowStyle',
      /** can receive a discount based on tier level */
      TierStatusDiscount: 'RewardsMarketplace.BillCredit.TierStatusDiscount',
      /** Enable SmartGrid bill credit */
      SmartGrid: 'RewardsMarketplace.BillCredit.SmartGrid',
      /** Enable Origin bill credit */
      Origin: 'RewardsMarketplace.BillCredit.Origin',
    },
    /** Enables shipping to PO Boxes */
    ShipToPOBoxes: 'RewardsMarketplace.ShipToPOBoxes',
    /** Show the rewards marketplace UI */
    UiVisible: 'RewardsMarketplace.UiVisible',
  },
  /** overall cash balance management & display features */
  Wallet: {
    /** allows a user to access wallet UI */
    View: 'Wallet.View',
    /** allows a user to access wallet eGift details UI */
    ViewEGift: 'Wallet.ViewEGift',
    /** display a link from Rewards Marketplace to Wallet */
    LinkFromRewards: 'Wallet.LinkFromRewards',
    /** allows transferring cash to gift cards */
    TransferToGiftCard: 'Wallet.TransferToGiftCard',
    /** allows transferring cash to PayPal */
    TransferToPayPal: 'Wallet.TransferToPayPal',
    /** Adds text on how to earn Cash */
    HowToEarnExplanation: 'Wallet.HowToEarnExplanation',
    /** Show the Telus Bravo Points earnings for a user */
    ShowTelusBravoPoints: 'Wallet.ShowTelusBravoPoints',
    /** Hide the back button/arrow on wallet */
    HideBackNavigate: 'Wallet.HideBackNavigate',
  },
  /** Payouts for Referral Bonuses and Prizes */
  Payments: {
    /** controls SmartGrid Watts to Bill Credit for referral payments */
    BillCredit: {
      /** can convert balance to bill credits */
      Transfer: 'Payments.BillCredit.Transfer',
    },
    /** controls paypal referral / prize payments */
    PayPal: {
      /** can transfer via paypal */
      Transfer: 'Payments.PayPal.Transfer',
      /** Validation/Eligibility limits when a user cashes out */
      CashoutLimits: {
        /** Adds a monthly cashout limit to paypal */
        Monthly: 'Payments.PayPal.CashoutLimits.Monthly',
        /** Removes any time based limit for paypal */
        NoTimeLimit: 'Payments.PayPal.CashoutLimits.NoTimeLimit',
      },
      /** specific email copy features for PayPal
       * NOTE: these apply to all paypal confirmation methods, both for rewards cashout or referal/cash balance */
      EmailCopy: {
        /** OhmPerks specific paypal email copy */
        OhmPerks: 'Payments.PayPal.EmailCopy.OhmPerks',
      },
    },
  },
  /** Features for the prioritized content engine */
  PrioritizedContentEngine: {
    /** PCE content is written to the caching layer when calculated, and read from the caching layer when available */
    UsePceStorageManager: 'PrioritizedContentEngine.UsePceStorageManager',
  },
  /** Features focusing on user privacy */
  Privacy: {
    /** Features related data protection laws (like GDRP). */
    DataProtection: 'Privacy.DataProtection',
  },
  /** Features associated with pixel trackers */
  PixelTracking: {
    /** Users/environments that we want to call the FB conversion API on */
    EnableFBCAPITracker: 'PixelTracking.EnableFBCAPITracker',
  },
  /** Features surrounding onboarding including signup and tutorial */
  Onboarding: {
    /** Features for signup */
    Signup: {
      /** signup utility selection page */
      UtilitySelection: {
        /** utility selection design refresh */
        Design2022Q1: 'Onboarding.Signup.UtilitySelection.Design2022Q1',
        /** Show the RHR V2/QC design on the utility selection screen */
        RhrQCBranding: 'Onboarding.Signup.UtilitySelection.RhrQCBranding',
      },
      /** utility handoff is a one-step screen, rather than a stepper */
      UtilityHandoffSingleStep: 'Onboarding.Signup.UtilityHandoffSingleStep',
      /** utility handback design refresh */
      UtilityHandbackDesign2022Q1: 'Onboarding.Signup.UtilityHandbackDesign2022Q1',
      /** utility handback design for Rhr flow */
      UtilityHandbackRhrVariation: 'Onboarding.Signup.UtilityHandbackRhrVariation',
      /** connect phone design refresh */
      ConnectPhoneDesign2022Q1: 'Onboarding.Signup.ConnectPhoneDesign2022Q1',
      /** show a message on connect phone page if utility is ineligible */
      ConnectPhoneIneligibleMessage: 'Onboarding.Signup.ConnectPhoneIneligibleMessage',
      /** verify phone design refresh */
      VerifyPhoneDesign2022Q1: 'Onboarding.Signup.VerifyPhoneDesign2022Q1',
      /** redirect from SignupChecklist back to / */
      SkipChecklist: 'Onboarding.Signup.SkipChecklist',
      /** Display the utility connection related steps of signup */
      UtilitySteps: 'Onboarding.Signup.UtilitySteps',
      /** Display the device offer step of signup */
      DeviceOfferSteps: 'Onboarding.Signup.DeviceOfferSteps',
      /** Show the perks variant of the no phone copy */
      PerksNoPhoneCopy: 'Onboarding.Signup.PerksNoPhoneCopy',
      /** Allows existing TX users to enroll with OCE */
      CanEnrollInRetailEnergy: 'Onboarding.Signup.CanEnrollInRetailEnergy',
      /** Display a promo upon signup appliance inventory completion */
      PromoAfterApplianceInventory: 'Onboarding.Signup.PromoAfterApplianceInventory',
      /** Eligible for abandoned enrollment recovery email */
      SendAbandondedEnrollmentEmail: 'Onboarding.Signup.SendAbandondedEnrollmentEmail',
      /** Disable sending of the email to confrm email address on Signup */
      DisableConfirmationEmail: 'Onboarding.Signup.DisableConfirmationEmail',
      /** Updates the create password signup page with a special design for QC */
      QCSignupPasswordDesign: 'Onboarding.Signup.QCSignupPasswordDesign',
      /** Redirect to signup app screens if user has feature */
      QCSignedUpRedirect: 'Onboarding.Signup.QCSignedUpRedirect',
    },
    /** Features for welcome modal */
    Welcome: {
      /** Skip welcome modal */
      SkipWelcome: 'Onboarding.Welcome.SkipWelcome',
      /** Send the Welcome SMS to users */
      SendSMS: 'Onboarding.Welcome.SendSMS',
      /** welcome screen redesign 2023 Q2 */
      Design2023Q2: 'Onboarding.Welcome.Design2023Q2',
      /** welcome screen copy for OCE */
      OhmTexasCopy: 'Onboarding.Welcome.OhmTexasCopy',
      /** welcome modal copy for ohm perks */
      OhmPerksCopy: 'Onboarding.Welcome.OhmPerksCopy',
      /** small dashboard welcome modal on first visit */
      ShowDashboardWelcomeModal: 'Onboarding.Welcome.ShowDashboardWelcomeModal',
      /** Features to display specific content on the dashboard Welcome screen */
      DashboardContent: {
        /** Origin dashboard welcome content */
        Origin: 'Onboarding.Welcome.DashboardContent.Origin',
      },
    },
    /** Features for first-time welcome tutorial */
    Tutorial: {
      /** Skip the tutorial screens */
      SkipTutorial: 'Onboarding.Tutorial.SkipTutorial',
      /** The Origin tutorial experience */
      Origin: 'Onboarding.Tutorial.Origin',
      /** Ohm tutorial variation focusing on devices, for experiment in ON-9057 */
      OhmDeviceTutorial: 'Onboarding.Tutorial.OhmDeviceTutorial',
      /** Features for redesigned tutorial 2023 Q2 */
      Design2023Q2: {
        /** tutorial variation for OhmClassic */
        OhmClassicTutorial: 'Onboarding.Tutorial.Design2023Q2.OhmClassicTutorial',
        /** tutorial variation for OhmTexas */
        OhmTexasTutorial: 'Onboarding.Tutorial.Design2023Q2.OhmTexasTutorial',
      },
    },
    /** Features for DR Device Control Acknowledgement & Consent */
    DeviceConsent: {
      /** Shows the consent screen during signup */
      ConsentScreen: 'Onboarding.DeviceConsent.ConsentScreen',
    },
    /** Features for onboarding-related upsell announcements */
    BillboardAnnouncement: {
      /** Announcement reminding users to connect their utility */
      UtilityConnection: {
        /** Whether the announcement is viewable */
        View: 'Onboarding.BillboardAnnouncement.UtilityConnection.View',
        /** Whether to prevent display of the announcement */
        Suppress: 'Onboarding.BillboardAnnouncement.UtilityConnection.Suppress',
      },
      /** Announcement reminding users to connect or purchase devices */
      DeviceConnection: {
        /** Whether the announcement is viewable */
        View: 'Onboarding.BillboardAnnouncement.DeviceConnection.View',
      },
    },
  },
  /** Features related to promos */
  Promo: {
    /** Features related to promo shipping */
    Shipping: {
      /** Feature indicating if a user is allowed to ship to any US state */
      AllowShippingToAnyUsState: 'Promo.Shipping.AllowShippingToAnyUsState',
    },
    /** Features related to the Device offer segment widget/device offer signup page */
    DeviceSegmentationOfferWidget: {
      /** Restricts seeing the device offer segment widget based on utility status */
      UtilityChecks: 'Promo.DeviceSegmentationOfferWidget.UtilityChecks',
    },
    /** Features relating to the Promo Offer page (formerly known as the "device offer" page) */
    DeviceOfferUI: {
      /** Show Perks users a message if they were shown the wrong promo on the marketing website */
      PerksShowAdvertisedPromoMessage: 'Promo.DeviceOfferUI.PerksShowAdvertisedPromoMessage',
      /** Show the 23Q2 Design variant of the promo offer page */
      Show23Q2Design: 'Promo.DeviceOfferUI.Show23Q2Design',
      /** Show a personalized offer message on the offer options page */
      ShowPersonalizedOfferMessage: 'Promo.DeviceOfferUI.ShowPersonalizedOfferMessage',
    },
    /** Features relating to the Promo payment handoff page */
    PaymentHandoffUI: {
      /** Show the 23Q2 Design variant of the promo payment handoff page */
      Show23Q2Design: 'Promo.PaymentHandoffUI.Show23Q2Design',
    },
    /** Features gating eligibility to promos */
    Eligibility: {},
    /** Features gating qualification to promos */
    Qualification: {},
    /** Display a promo on the PCE promo module until it has been awarded, does not apply to BYOD promos */
    DisplayOnPCEUntilAwarded: 'Promo.DisplayOnPCEUntilAwarded',
    /** Skip the 30 day waiting period for rewarding promos */
    SkipWaitingPeriod: 'Promo.SkipWaitingPeriod',
    /** Check the Qualification of a promo based on compound segment if user has first_qualfied_dttm */
    CheckQualificationBasedOnCompoundSegment: 'Promo.CheckQualificationBasedOnCompoundSegment',
  },
  /** For everything regarding Reduction Events! */
  ReductionEvents: {
    /** vary display based on presence of utility data energy reduction results */
    AwaitReductionResults: 'ReductionEvents.AwaitReductionResults',
    /** for specific UI Features on the event page */
    EventsUI: {
      /** show detailed placeholder event when user's never had an event and non are planned */
      ShowDetailedPreEventsFlag: 'ReductionEvents.EventsUI.ShowDetailedPreEventsFlag',
      /** show basic placeholder event when user's never had an event and non are planned */
      ShowBasicPreEventsFlag: 'ReductionEvents.EventsUI.ShowBasicPreEventsFlag',
      /** show placeholder event when user has no devices */
      ShowPreDevicesFlag: 'ReductionEvents.EventsUI.ShowPreDevicesFlag',
      /** Show the X previous events section and the download csv link */
      ShowHistoricalEventsSection: 'ReductionEvents.EventsUI.ShowHistoricalEventsSection',
      /** placeholder when no events are present */
      PreEventsFlag: {
        /** Show special watt amount for Perks with reduced watt amount */
        DisplayPerksReducedWatts: 'ReductionEvents.EventsUI.PreEventsFlag.DisplayPerksReducedWatts',
        /** Copy variation for OhmPerks program */
        OhmPerksCopy: 'ReductionEvents.EventsUI.PreEventsFlag.OhmPerksCopy',
        /** Copy variation for OhmPerks Limbo programs */
        OhmPerksLimboCopy: 'ReductionEvents.EventsUI.PreEventsFlag.OhmPerksLimboCopy',
        /** Show the X previous events section and the download csv link */
        ShowHistoricalEventsSection:
          'ReductionEvents.EventsUI.PreEventsFlag.ShowHistoricalEventsSection',
      },
    },
    /** for specific UI Features on the event details page */
    EventDetailsUI: {
      /** features related to UX of first completed event */
      UsersFirstEventUI: {
        /** whether to display the special content */
        ShowFirstEventUI: 'ReductionEvents.EventDetailsUI.UsersFirstEventUI.ShowFirstEventUI',
        /** whether to prompt user to get devices */
        ShowDevicePrompt: 'ReductionEvents.EventDetailsUI.UsersFirstEventUI.ShowDevicePrompt',
      },
      /** confetti animation when viewing successful event */
      SuccessConfetti: 'ReductionEvents.EventDetailsUI.SuccessConfetti',
      /** the banner telling OIU users that they won't have real data */
      OhmInternalUtilityNoDataBanner:
        'ReductionEvents.EventDetailsUI.OhmInternalUtilityNoDataBanner',
      /** visualize energy performance for the event in a bar chart */
      EnergyPerformanceChart: 'ReductionEvents.EventDetailsUI.EnergyPerformanceChart',
      /** for UI Features specific to the event details device list */
      DeviceList: {
        /** show the devices list above event performance rather than below */
        ShowAboveEventPerformance:
          'ReductionEvents.EventDetailsUI.DeviceList.ShowAboveEventPerformance',
        /** show no-device CTA */
        ShowNoDevicesCTA: 'ReductionEvents.EventDetailsUI.DeviceList.ShowNoDevicesCTA',
        /** show no-thermostat CTA */
        ShowNoThermostatCTA: 'ReductionEvents.EventDetailsUI.DeviceList.ShowNoThermostatCTA',
        /** Show the perks variant of the no devices copy */
        PerksNoDevicesCopy: 'ReductionEvents.EventDetailsUI.DeviceList.PerksNoDevicesCopy',
        /** Show the perks variant of the no thermostat copy */
        PerksNoThermostatCopy: 'ReductionEvents.EventDetailsUI.DeviceList.PerksNoThermostatCopy',
      },
      /** show energy performance results in Event Summary */
      EnergyPerformanceSummary: 'ReductionEvents.EventDetailsUI.EnergyPerformanceSummary',
      /** content related to event forgiveness */
      Forgiveness: {},
      /** influences display of opt out controls */
      EventOptOutControls: {
        /** make the button very prominent */
        ProminentButton: 'ReductionEvents.EventDetailsUI.EventOptOutControls.ProminentButton',
        /** group the button with the event performance chart */
        ShowButtonWithPerfChart:
          'ReductionEvents.EventDetailsUI.EventOptOutControls.ShowButtonWithPerfChart',
        /** show the button as a standalone page element */
        ShowButtonStandalone:
          'ReductionEvents.EventDetailsUI.EventOptOutControls.ShowButtonStandalone',
      },
      /** show whole-event opt out for AutoOhms */
      ShowRealtimeEventOptOut: 'ReductionEvents.EventDetailsUI.ShowRealtimeEventOptOut',
    },
    /** Allows user to pause events for an amount of time */
    VacationMode: 'ReductionEvents.VacationMode',
    /** Performance in real-time events is considered when calculating user's tier */
    RealTimePerformanceAffectsTier: 'ReductionEvents.RealTimePerformanceAffectsTier',
    /** Real time events (AutoOhms) allow for opt-out */
    RealTimeEventOptOut: 'ReductionEvents.RealTimeEventOptOut',
    /** Features relating to how we reward users during energy events; 9x out of 10 if you are thinking of putting something here, it should go into an algorithm. */
    Rewards: {
      /** Do we gate users from receiving rewards *at all* if they are below Silver? */
      RewardUserBasedOnSilverPlus: 'ReductionEvents.Rewards.RewardUserBasedOnSilverPlus',
      /** If exposed, we reward users regardless of their tier -- even if they are below Silver. */
      RewardUserRegardlessOfTier: 'ReductionEvents.Rewards.RewardUserRegardlessOfTier',
    },
  },
  /** features relating to presentational ui elements */
  Presentational: {
    /** list item */
    ListItem: {
      /** show card style design originated in 2022 Q1 */
      CardStyleDesign: 'Presentational.ListItem.CardStyleDesign',
    },
    /** nba placements */
    NbaPlacement: {
      /** show card style design originated in 2022 Q1 */
      CardStyleDesign: 'Presentational.NbaPlacement.CardStyleDesign',
    },
    /** Flat square tabs originally designed for Origin */
    FlatTabs: 'Presentational.FlatTabs',
    /** UI features related to utility pages */
    Utility: {
      /** Always Show the Get Help button on the Utility details page(s) */
      AlwaysShowHelpCTA: 'Presentational.Utility.AlwaysShowHelpCTA',
      /** Show the meter details page on settings (Origin specific page) */
      MeterDetailsPage: 'Presentational.Utility.MeterDetailsPage',
    },
    /** features relating to primary app-wide navigation */
    PrimaryNav: {
      /** Hide the global nav bar */
      Hide: 'Presentational.PrimaryNav.Hide',
      /** icon variant with cash symbol */
      RewardsCashIcon: 'Presentational.PrimaryNav.RewardsCashIcon',
      /** Hide the nav only on wallet */
      HideOnWallet: 'Presentational.PrimaryNav.HideOnWallet',
    },
    /** features relating to the tier status badge */
    StatusBadge: {
      /** flat UI style from 2022 Q3 / Impact Page */
      DesignFlat: 'Presentational.StatusBadge.DesignFlat',
    },
  },
  /** all things regarding referrals */
  Referrals: {
    /** having to do with the awarded cash from a referral */
    Awards: {
      /** referral awards do not expire */
      NoExpiration: 'Referrals.Awards.NoExpiration',
    },
    /** show the cta which redirects to settings payments to transfer bonuses */
    ShowTransferCTAToSettingsPayments: 'Referrals.ShowTransferCTAToSettingsPayments',
    /** show the cta which redirects to Wallet feature to transfer bonuses */
    ShowTransferCTAToWallet: 'Referrals.ShowTransferCTAToWallet',
    /** Referrals are tied to the OhmClassic experience */
    RefersToOhmClassic: 'Referrals.RefersToOhmClassic',
    /** show the referral ribbon button in the app */
    ShowReferralRibbon: 'Referrals.ShowReferralRibbon',
    /** features related to the third-party friend buy integration */
    FriendBuy: {
      /** use friendbuy as the referrals system */
      UseForReferrals: 'Referrals.FriendBuy.UseForReferrals',
      /** show the friendbuy advocate dashboard on the referral page */
      ShowAdvocateDashboard: 'Referrals.FriendBuy.ShowAdvocateDashboard',
      /** show the friendbuy partner advocate dashboard variant for mosaic */
      UseAdvocateDashboardVariantPartnerMosaic:
        'Referrals.FriendBuy.UseAdvocateDashboardVariantPartnerMosaic',
      /** show the friendbuy partner advocate dashboard variant for Carrier CA/NY */
      UseAdvocateDashboardVariantPartnerCarrierCaNy:
        'Referrals.FriendBuy.UseAdvocateDashboardVariantPartnerCarrierCaNy',
      /** show the friendbuy partner advocate dashboard variant for Carrier TX */
      UseAdvocateDashboardVariantPartnerCarrierTx:
        'Referrals.FriendBuy.UseAdvocateDashboardVariantPartnerCarrierTx',
      /** show the friendbuy partner advocate dashboard variant for Energy Solutions */
      UseAdvocateDashboardVariantPartnerEnergySolutions:
        'Referrals.FriendBuy.UseAdvocateDashboardVariantPartnerEnergySolutions',
      /** show the friendbuy partner advocate dashboard variant for Rheem */
      UseAdvocateDashboardVariantPartnerRheem:
        'Referrals.FriendBuy.UseAdvocateDashboardVariantPartnerRheem',
      /** Should we post *any* info to FriendBuy -- distinct from getting the FriendBuy experience */
      PostDataToFriendBuy: 'Referrals.FriendBuy.PostDataToFriendBuy',
      /** Should new leadgen users be sent a welcome email */
      SendLeadGenReferralsWelcomeEmail: 'Referrals.FriendBuy.SendLeadGenReferralsWelcomeEmail',
      /** Should new partner advocates be sent a welcome email */
      SendPartnerReferralsWelcomeEmail: 'Referrals.FriendBuy.SendPartnerReferralsWelcomeEmail',
      /** Should new carrier advocates be sent a welcome email */
      SendCarrierReferralsWelcomeEmail: 'Referrals.FriendBuy.SendCarrierReferralsWelcomeEmail',
      /** Friends are not eligible for incentives (used for Partner Referral programs) */
      DisableFriendIncentives: 'Referrals.FriendBuy.DisableFriendIncentives',
    },
    /** features related to the UI of the referrals page */
    ReferralsUI: {
      /** display a simple page nav header */
      UseBasicBrandBar: 'Referrals.ReferralsUI.UseBasicBrandBar',
      /** allow access to the referral offer page */
      ShowReferralOfferPage: 'Referrals.ReferralsUI.ShowReferralOfferPage',
      /** display a message for users who can only refer others */
      ShowReferralsOnlyMessage: 'Referrals.ReferralsUI.ShowReferralsOnlyMessage',
      /** referrals only copy for Texas users */
      ReferralsOnlyTXCopy: 'Referrals.ReferralsUI.ReferralsOnlyTXCopy',
      /** leadgen referrals copy on invite page */
      ShowLeadgenReferralsMessage: 'Referrals.ReferralsUI.ShowLeadgenReferralsMessage',
      /** display the QR code button */
      ShowReferralLinkQRCode: 'Referrals.ReferralsUI.ShowReferralLinkQRCode',
      /** display the wallet on the referrals page */
      ShowWalletBalance: 'Referrals.ReferralsUI.ShowWalletBalance',
      /** display a downloadable pdf on the referrals page */
      ShowDownloadablePDFLink: 'Referrals.ReferralsUI.ShowDownloadablePDFLink',
      /** display a referral leaderboard on the referrals page */
      ShowReferralLeaderboard: 'Referrals.ReferralsUI.ShowReferralLeaderboard',
      /** display referral page to users */
      ShowReferralPage: 'Referrals.ReferralsUI.ShowReferralPage',
      /** display referral navlink to users */
      ShowReferralNavlink: 'Referrals.ReferralsUI.ShowReferralNavlink',
      /** display the PDF for ES Partner Referrals */
      ShowESPartnerReferralPDF: 'Referrals.ReferralsUI.ShowESPartnerReferralPDF',
    },
    /** features related to the different PDF types */
    PDFLinks: {
      /** displays California-specific PDF */
      ShowOhmCaliforniaPDFLink: 'Referrals.PDFLinks.ShowOhmCaliforniaPDFLink',
      /** display Partner Mosaic-specific PDF */
      ShowPartnerMosaicPDFLink: 'Referrals.PDFLinks.ShowPartnerMosaicPDFLink',
    },
  },
  /** Connections */
  Connections: {
    /** features related to the explainer sheet on the Accounts/Link Devices screen */
    ConnectionsPageExplainerSheetUI: {
      /** show a message explaining that linked devices enable participation in realtime events */
      ShowRealtimeEventEligibility:
        'Connections.ConnectionsPageExplainerSheetUI.ShowRealtimeEventEligibility',
      /** show a message explaining that linked devices earn bonus watts in events */
      ShowDeviceBonusWatts: 'Connections.ConnectionsPageExplainerSheetUI.ShowDeviceBonusWatts',
      /** include realtime events in the message about automated savings during events */
      IncludeRealtimeEventsInAutomationMessage:
        'Connections.ConnectionsPageExplainerSheetUI.IncludeRealtimeEventsInAutomationMessage',
    },
    /** features related to Google nest devices */
    GoogleNest: {
      /** features related to which Google Nest flow the user experiences */
      ApiType: {
        /** user gets SDM-only flow */
        SdmOnly: 'Connections.GoogleNest.ApiType.SdmOnly',
        /** user gets SDM->RHR flow */
        GoogleSdmAndRhr: 'Connections.GoogleNest.ApiType.GoogleSdmAndRhr',
      },
      /** features related to Google Rush Hour Rewards */
      GoogleRhr: {
        /** features relating to user interface elements directing users to enroll with RHR */
        NeedsRhrEnrollmentUI: {
          /** display a 1 time full page takeover prompting the user to enroll */
          FullDashboardTakeover:
            'Connections.GoogleNest.GoogleRhr.NeedsRhrEnrollmentUI.FullDashboardTakeover',
          /** Redirects all attempts to enroll again to our holding pen */
          EnrollmentLimboUI:
            'Connections.GoogleNest.GoogleRhr.NeedsRhrEnrollmentUI.EnrollmentLimboUI',
        },
      },
    },
    /** Features relating to adding and managing Nests via the SDM connection */
    NestSdm: {
      /** Gate the Nest SDM connection behind a `coming soon` button */
      ComingSoon: 'Connections.NestSdm.ComingSoon',
      /** Allow users to link Nest devices via the SDM connection */
      EnableOverride: 'Connections.NestSdm.EnableOverride',
    },
    /** Features relating to adding and managing LG devices */
    LG: {
      /** Overrides support for LG Devices -- only users exposed to this will see LG devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.LG.AllowAddNew',
    },
    /** Features relating to adding and managing LG QA devices */
    LG_QA: {
      /** Overrides support for LG QA Devices -- only users exposed to this will see LG QA devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.LG_QA.AllowAddNew',
    },
    /** Features relating to adding and managing Honeywell devices */
    Honeywell: {
      /** Overrides support for Honeywell Devices -- only users exposed to this will see Honeywell devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.Honeywell.AllowAddNew',
    },
    /**  */
    AmazonAlexa: {
      /** Overrides support for AmazonAlexa Devices -- only users exposed to this will see AmazonAlexa devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.AmazonAlexa.AllowAddNew',
    },
    /**  */
    SunPower: {
      /** Overrides support for SunPower Devices -- only users exposed to this will see SunPower devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.SunPower.AllowAddNew',
    },
    /**  */
    Carrier: {
      /** Overrides support for Carrier Devices -- only users exposed to this will see Carrier devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.Carrier.AllowAddNew',
    },
    /**  */
    GoogleHome: {
      /** Overrides support for GoogleHome Devices -- only users exposed to this will see GoogleHome devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.GoogleHome.AllowAddNew',
    },
    /**  */
    HomeConnect: {
      /** Overrides support for HomeConnect Devices -- only users exposed to this will see HomeConnect devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.HomeConnect.AllowAddNew',
    },
    /** Features relating to adding and managing Rheem devices */
    Rheem: {
      /** Overrides support for Rheem Devices -- only users exposed to this will see Rheem devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.Rheem.AllowAddNew',
    },
    /** Features relating to adding and managing RheemConnect devices */
    RheemConnect: {
      /** Overrides support for RheemConnect Devices -- only users exposed to this will see RheemConnect devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.RheemConnect.AllowAddNew',
    },
    /** Features relating to adding and managing RheemConnect QA devices */
    RheemConnect_QA: {
      /** Overrides support for RheemConnect QA Devices -- only users exposed to this will see RheemConnect devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.RheemConnect_QA.AllowAddNew',
    },
    /** Features relating to adding and managing Sense devices */
    Sense: {
      /** Overrides support for Sense Devices -- only users exposed to this will see Sense devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.Sense.AllowAddNew',
    },
    /** Features relating to adding and managing TP-Link Tapo devices */
    TplinkTapo: {
      /** Overrides support for TP-Link Tapo Devices -- only users exposed to this will see Tapo devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.TplinkTapo.AllowAddNew',
    },
    /** Features relating to adding and managing OhmTest devices */
    OhmTest: {
      /** Overrides support for OhmTest Devices -- only users exposed to this will see OhmTest devices if the connection configs have this in `support_override_gating_feature` */
      AllowAddNew: 'Connections.OhmTest.AllowAddNew',
    },
  },
  /** Devices */
  Devices: {
    /**  */
    DevicesPageUI: {
      /** Show the device event details banner */
      ShowDeviceEventDetailsBanner: 'Devices.DevicesPageUI.ShowDeviceEventDetailsBanner',
      /** Show weekly device usage */
      ShowWeeklyDeviceUsage: 'Devices.DevicesPageUI.ShowWeeklyDeviceUsage',
      /** Show appliance inventory cta */
      ShowApplianceInventoryCta: 'Devices.DevicesPageUI.ShowApplianceInventoryCta',
    },
    /**  */
    DeviceDetailsPageUI: {
      /** Show the new device details page */
      Show2023Q1DeviceDetailsPage: 'Devices.DeviceDetailsPageUI.Show2023Q1DeviceDetailsPage',
    },
    /** features related to the device page header */
    DevicesPageHeaderUI: {
      /** show a message when the user is in utility limbo */
      ShowLimboUtilityMessage: 'Devices.DevicesPageHeaderUI.ShowLimboUtilityMessage',
      /** show a message when the user is in device no-order limbo */
      ShowLimboDeviceNoOrderMessage: 'Devices.DevicesPageHeaderUI.ShowLimboDeviceNoOrderMessage',
      /** show a message when the user is in device ordered limbo */
      ShowLimboDeviceOrderedMessage: 'Devices.DevicesPageHeaderUI.ShowLimboDeviceOrderedMessage',
    },
    /** Billboard announcements */
    BillboardAnnouncement: {
      /** Announcement to show plus users their device payout table */
      DevicePayoutTable: {
        /** Whether the billboard is viewable */
        View: 'Devices.BillboardAnnouncement.DevicePayoutTable.View',
      },
    },
    /** Features related to the device order logistics */
    OrderLogisticsUI: {
      /** Show the order logistics ui in devices lists */
      Show: 'Devices.OrderLogisticsUI.Show',
    },
    /** Features related to the devices list */
    DevicesListUI: {
      /** Show the segmented devices list with reward container */
      RewardContainerSegmentedDevicesList:
        'Devices.DevicesListUI.RewardContainerSegmentedDevicesList',
    },
    /** Features related to device status ui */
    DeviceStatusUI: {
      /** Whether we should show the pre-event participation state as status */
      ShowDevicePreEventParticipationStateInStatus:
        'Devices.DeviceStatusUI.ShowDevicePreEventParticipationStateInStatus',
    },
    /** Features related to device details ui */
    DeviceDetailsUI: {
      /** show watts earned in the device details status and event history */
      ShowWattsData: 'Devices.DeviceDetailsUI.ShowWattsData',
      /** show the device's status in each event in the history */
      ShowEventHistoryStatus: 'Devices.DeviceDetailsUI.ShowEventHistoryStatus',
      /** show the options to select which type of events a device should participate in (OhmHours/AutoOhms) */
      ShowAutomationPreferences: 'Devices.DeviceDetailsUI.ShowAutomationPreferences',
      /** Show device_toggle information in the device event history section */
      DeviceToggleHistory: 'Devices.DeviceDetailsUI.DeviceToggleHistory',
      /** Show the energy usage chart */
      DeviceEnergyUsageChart: 'Devices.DeviceDetailsUI.DeviceEnergyUsageChart',
      /** Show the energy usage monthly chart */
      DeviceEnergyUsageMonthlyChart: 'Devices.DeviceDetailsUI.DeviceEnergyUsageMonthlyChart',
    },
    /** use a single device connection help url from cnf file instead of device specific urls from db */
    UseSingleDeviceConnectionHelpUrl: 'Devices.UseSingleDeviceConnectionHelpUrl',
  },
  /** Settings */
  Settings: {
    /** display event eligibility in settings */
    EventEligibility: {
      /** show the section on settings page */
      ShowSection: 'Settings.EventEligibility.ShowSection',
      /** link to a detail view of section */
      LinkDetails: 'Settings.EventEligibility.LinkDetails',
      /** evaluate event eligibility for OhmPerks */
      OhmPerksEligibility: 'Settings.EventEligibility.OhmPerksEligibility',
      /** event eligible by Perks criteria */
      OhmPerksEventEligible: 'Settings.EventEligibility.OhmPerksEventEligible',
    },
    /** display and link to utility status & event eligibility page from settings */
    UtilityAndEventsSectionLink: 'Settings.UtilityAndEventsSectionLink',
    /** Link to devices list from settings */
    DevicesLink: {
      /** Hide the devices link from the settings page */
      Hide: 'Settings.DevicesLink.Hide',
    },
    /** Link to events list from settings */
    EventsLink: {
      /** Hide the events link from the settings page */
      Hide: 'Settings.EventsLink.Hide',
    },
    /** Event notifications page */
    EventNotifications: {
      /** copy variations for AutoOhms programs which do not require devices */
      BehavioralAutoOhmsCopy: 'Settings.EventNotifications.BehavioralAutoOhmsCopy',
      /** show descriptions and copy for perks */
      OhmPerksCopy: 'Settings.EventNotifications.OhmPerksCopy',
      /** allow auto ohm subscription settings to be changed */
      AutoOhmSubscriptionChanges: 'Settings.EventNotifications.AutoOhmSubscriptionChanges',
      /** display auto ohm subscription settings section */
      ShowAutoOhmSubscriptions: 'Settings.EventNotifications.ShowAutoOhmSubscriptions',
      /** copy changes when notifications are not required for event dispatch (such as QuietClassic) */
      NotificationsNotRequiredForEvents:
        'Settings.EventNotifications.NotificationsNotRequiredForEvents',
    },
    /** referral and prize bonuses section */
    ReferralAndPrizeBonuses: {
      /** show referral and prize bonuses section */
      Show: 'Settings.ReferralAndPrizeBonuses.Show',
      /** Hide mention of prizes */
      HidePrizeCopy: 'Settings.ReferralAndPrizeBonuses.HidePrizeCopy',
    },
    /** Hide the logout button (Needed for Origin) */
    NoLogoutOption: 'Settings.NoLogoutOption',
    /** features related to the settings privacy page */
    Privacy: {
      /** show a link to the ohm classic program details */
      OhmClassicSimpleSegmentDetails: 'Settings.Privacy.OhmClassicSimpleSegmentDetails',
      /** show a link to the ohm perks program details */
      OhmPerksSimpleSegmentDetails: 'Settings.Privacy.OhmPerksSimpleSegmentDetails',
      /** show a link to the OhmConnect Energy Usage information Policy */
      EnergyUsageInformation: 'Settings.Privacy.EnergyUsageInformation',
    },
  },
  /** features for integrations with external systems */
  Integrations: {
    /** features for integration with Delighted customer feedback platform */
    Delighted: {
      /** Skip sending CES device survey for users with this feature */
      SkipDeviceSurvey: 'Integrations.Delighted.SkipDeviceSurvey',
      /** Skip sending CES utility survey for users with this feature */
      SkipUtilitySurvey: 'Integrations.Delighted.SkipUtilitySurvey',
      /** features for in-app PMF survey */
      PMFSurvey: {
        /** force PMF survey availability for pre AuthDone users */
        ExposePMFSurveyPreAuthDone: 'Integrations.Delighted.PMFSurvey.ExposePMFSurveyPreAuthDone',
        /** block PMF survey availability for pre AuthDone users */
        ExposePMFSurveyPostAuthDone: 'Integrations.Delighted.PMFSurvey.ExposePMFSurveyPostAuthDone',
      },
    },
    /** features for integration with SGCIS */
    SmartGridCIS: {
      /** sync information back to SmartGrid via custom attributes */
      CustomAttributeSync: 'Integrations.SmartGridCIS.CustomAttributeSync',
    },
  },
  /** 2022Q3 new feature initiative to get users their first device */
  UsersFirstDevice: {
    /** Features for the offering the user a free ohm plug as their first device */
    FreeDevice: {
      /** Can redeem the offer and get a free ohm plug */
      Redeem: 'UsersFirstDevice.FreeDevice.Redeem',
      /** CTA for a free ohmplug on the connections list */
      ConnectionListCallout: 'UsersFirstDevice.FreeDevice.ConnectionListCallout',
      /** show the info card informing user of their device pre-shipping */
      DeviceOrderNotShipped: 'UsersFirstDevice.FreeDevice.DeviceOrderNotShipped',
      /** show the info card informing user that their device has been shipped */
      DeviceOrderShipped: 'UsersFirstDevice.FreeDevice.DeviceOrderShipped',
      /** show the info card informing user that their device has been delivered */
      DeviceOrderDelivered: 'UsersFirstDevice.FreeDevice.DeviceOrderDelivered',
    },
    /** Features relating to giving users watts for their first device */
    WattsForFirstDevice: {
      /** do the actual awarding of watts for the first device */
      AwardWatts: 'UsersFirstDevice.WattsForFirstDevice.AwardWatts',
      /** show the info card informing the user that they can earn 1000 watts for connecting a device */
      ShowNoDevicesAwardWattsCTACopy:
        'UsersFirstDevice.WattsForFirstDevice.ShowNoDevicesAwardWattsCTACopy',
    },
    /** Features relating to giving user bill credit when they link their first device which is a thermostat */
    BillCreditForFirstThermostat: {
      /** Feature that determines eligibility to receive a bill credit when a first thermostat is linked */
      Eligible: 'UsersFirstDevice.BillCreditForFirstThermostat.Eligible',
      /** Feature that determines that a user is qualified to receive a bill credit after they link their first thermostat */
      Qualified: 'UsersFirstDevice.BillCreditForFirstThermostat.Qualified',
    },
    /** show the info card informing the user that they have no devices yet */
    ShowNoDevicesCTACopy: 'UsersFirstDevice.ShowNoDevicesCTACopy',
  },
  /** because I was promised $1 for doing this */
  DoInFactMessWith: 'DoInFactMessWith',
  /** Features relating to email/sms subscriptions */
  Subscriptions: {
    /** Mute all subscriptions that are not relevant for Quiet Classic */
    QuietClassic: 'Subscriptions.QuietClassic',
  },
  /** Experiences */
  Experiences: {
    /** Whether or not the user is considered to be a part of the EMS experience.
     * Do not use this to gate individual experiences, rather this is for identifying the general
     * experience at a high level for communicating with CX, reporting, and user tracking. */
    HasEmsExperience: 'Experiences.HasEmsExperience',
    /** Whether or not the user is considered to be a part of the QuietClassic experience.
     * Do not use this to gate individual experiences, rather this is for identifying the general
     * experience at a high level for communicating with CX, reporting, and user tracking. */
    HasQuietClassicExperience: 'Experiences.HasQuietClassicExperience',
    /** Features relating to the appliance inventory */
    ApplianceInventory: {
      /** Show the appliance inventory */
      ShowApplianceInventory: 'Experiences.ApplianceInventory.ShowApplianceInventory',
      /** Award watts for appliance inventory completion */
      AwardWattsForCompletion: 'Experiences.ApplianceInventory.AwardWattsForCompletion',
      /** Show the recommendations page after appliance inventory completion */
      ShowRecommendationsPage: 'Experiences.ApplianceInventory.ShowRecommendationsPage',
      /** Show the appliance inventory initial completion modal */
      ShowInitialCompletionModal: 'Experiences.ApplianceInventory.ShowInitialCompletionModal',
    },
    /** Features related to the dashboard experience */
    Dashboard: {
      /** Show the EMS dashboard experience */
      ShowEmsExperience: 'Experiences.Dashboard.ShowEmsExperience',
      /** Features related to the dashboard events experience */
      Events: {
        /** Show the no-device dashboard events experience */
        ShowNoDeviceExperience: 'Experiences.Dashboard.Events.ShowNoDeviceExperience',
        /** Show the has-device dashboard events experience */
        ShowHasDeviceExperience: 'Experiences.Dashboard.Events.ShowHasDeviceExperience',
      },
    },
    /** Features related to the event list and details experience */
    EnergyEvents: {
      /** Show the EMS Energy Events experience */
      ShowEmsExperience: 'Experiences.EnergyEvents.ShowEmsExperience',
      /** Show the no-device dashboard events experience */
      ShowNoDeviceExperience: 'Experiences.EnergyEvents.ShowNoDeviceExperience',
      /** Show the has-device dashboard events experience */
      ShowHasDeviceExperience: 'Experiences.EnergyEvents.ShowHasDeviceExperience',
    },
    /** Features related to the account setup experience */
    AccountSetup: {
      /** Show the EMS account setup */
      ShowEmsAcountSetupExperience: 'Experiences.AccountSetup.ShowEmsAcountSetupExperience',
    },
    /** Features related to the recaps experience */
    Recaps: {
      /** Show the EMS recaps experience */
      ShowEmsRecapsExperience: 'Experiences.Recaps.ShowEmsRecapsExperience',
    },
  },
};
