/*  Copyright (C) 2020 OhmConnect, Inc. - All Rights Reserved  */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {NodeEnv} from 'config/environments/env.types';
import {ENV} from 'config/globals';
import {captureException} from 'utils';

import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
// Supports weights 100-900
import '@fontsource-variable/inter';

if (ENV.nodeEnv !== NodeEnv.DEVELOPMENT) {
  Sentry.init({
    dsn: 'https://01eda4b633ea4106b5daa85e2889ee5e@o388251.ingest.sentry.io/5224856',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.15,
    environment: `${ENV.nodeEnv}-${ENV.oem}`,
    denyUrls: [
      /https?:\/\/cdn\.cookielaw\.org/i,
      /https?:\/\/(public|static)\.fbot\.me/i,
      /\/\/[\w\d]+\.bablic\.com/i,
      /https?:\/\/[\w\d]+\.onetrust\.com/i,
    ],
  });
}

function initializeSnowplow(tries) {
  if (tries > 30) captureException(new Error("Snowplow couldn't initialize after 30 tries"));
  if (!window.snowplow) return setTimeout(() => initializeSnowplow(tries + 1), 150);

  window.snowplowActive = true;
  window.snowplow('newTracker', 'sp', ENV.snowplowCollectorUrl, {
    appId: 'ohm_spa',
    cookieDomain: null,
    platform: 'web',
    postPath: '/com.ohmconnect/tr',
    contexts: {
      webPage: true,
      performanceTiming: true,
    },
  });
}

window.dataLayer = window.dataLayer || [];
window.friendbuyAPI = window.friendbuyAPI || [];
window.snowplowActive = false;
window.gtmActive = false;
if (ENV.nodeEnv === 'production' && ENV.snowplowCollectorUrl) {
  initializeSnowplow(0);
  window.gtmActive = true;
}

// Init FullStory on all environments so that we don't have to check environment
// each time we call a FullStory method
FullStory.init({orgId: ENV.fullstoryOrganizationId, devMode: ENV.nodeEnv !== 'production'});
// immediately shut down if privacy flag is set
FullStory.shutdown();
if (
  !window.OnetrustActiveGroups ||
  (window?.OnetrustActiveGroups ?? '').split(',').includes(ENV.cookieTargetingAllowedId)
) {
  // resume data capture if privacy flag is not set
  FullStory.restart();
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
